import React from "react";
import Layout from "../components/layout";
import Terms from "../components/legal/Terms";
import Seo from "../components/seo";

const ReturnPage = () => (
  <Layout>
    <Seo title="Terms & Conditions" />
    <Terms />
  </Layout>
);

export default ReturnPage;
